import * as React from 'react'
import Paper from '@mui/material/Paper'
import BottomNavigation from '@mui/material/BottomNavigation'
import BottomNavigationAction from '@mui/material/BottomNavigationAction'
import { useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import useNavStore from 'Store/navStore'
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';
import SpaceDashboardOutlinedIcon from '@mui/icons-material/SpaceDashboardOutlined';
import TodayIcon from '@mui/icons-material/Today';
import TodayOutlinedIcon from '@mui/icons-material/TodayOutlined';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';
import QueueIcon from '@mui/icons-material/Queue';
import QueueOutlinedIcon from '@mui/icons-material/QueueOutlined';
import BallotIcon from '@mui/icons-material/Ballot';
import BallotOutlinedIcon from '@mui/icons-material/BallotOutlined';
import { useTranslation } from 'react-i18next'
import { useLocation } from "react-router-dom";
import useFileStore from "Store/fileStore";

export default function LabelBottomNavigation() {
  const location = useLocation();
  const navigate = useNavigate()
  const {t} = useTranslation();
  const { triggerFileInput } = useFileStore()
  const { navigation, setActive } = useNavStore()

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
    // setActive(newValue)
    navigate(newValue)
  }

  return (
    <Box sx={{ pb: 7, borderRadius: '10px 10px 0 0' }}>
      <CssBaseline />

      <Paper
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          width: '100%',
          textAlign: '-webkit-center',
          borderRadius: '10px 10px 0 0',
          zIndex: 2,
        }}
        elevation={3}
      >
        <BottomNavigation
          showLabels
          // value={navigation.active}
          value={location.pathname}
          onChange={handleChange}
          sx={{ borderRadius: '10px 10px 0 0' }}
        >
          <BottomNavigationAction
            color={navigation.active === 1 ? 'secondary' : 'primary'}
            value='/dashboard'
            label={t('nav_home')}
            icon={location.pathname === '/dashboard' ? <SpaceDashboardIcon color="secondary" /> : <SpaceDashboardOutlinedIcon />}
          />
          <BottomNavigationAction
            value='/events'
            label={t('nav_my_events')}
            icon={location.pathname === '/events' ? <TodayIcon color="secondary" /> : <TodayOutlinedIcon />}
          />
          <BottomNavigationAction
            // value='/upload'
            onClick={triggerFileInput}
            label={t('nav_upload')}
            // eslint-disable-next-line no-constant-condition
            icon={false ? <QueueIcon color="secondary" /> : <QueueOutlinedIcon  />}
          />
          {/* <BottomNavigationAction
            value='/notifications'
            label={t('nav_notifications')}
            icon={location.pathname === '/notifications' ?<BallotIcon color="secondary" />: <BallotOutlinedIcon  />}
          /> */}
          <BottomNavigationAction
            value='/profile'
            label={t('nav_profile')}
            icon={
              location.pathname === '/profile' ? (
                <AssignmentIndIcon color="secondary" />
              ) : (
                <AssignmentIndOutlinedIcon />
              )
            }
          />
        </BottomNavigation>
      </Paper>
    </Box>
  )
}
