import * as React from 'react'
import Box from '@mui/material/Box'
import Header from 'Components/Header/Header'
import BottomNavigation from 'Components/BottomNavigation'
import FileUpload from 'Components/FileUpload'

interface Props {
  children: React.ReactNode
}
const Layout: React.FC<Props> = (props) => {
  return (
    <Box>
      <Header />
      {props.children}
    </Box>
  )
}
export default Layout
