import React, { ReactNode } from "react";
import { useParams } from "react-router-dom";
import Link, { LinkProps } from "@mui/material/Link";

interface CustomLinkProps extends LinkProps {
    children: ReactNode;
}

export default function CustomLink(props: CustomLinkProps) {
    const { id } = useParams();

    return (
        <Link
            {...props}
            href={`${id?'/'+id:''}/${props.href}`}
        >
            {props.children}
        </Link>
    );
}