import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { User, LoginRequest, UserResponse, RegisterRequest, UserRequest } from 'Models/user'
import service from '../Services'
import useEventStore from 'Store/eventStore'
// import { useClerk } from '@clerk/clerk-react'

interface State {
  token: string | null
  user: User | null
  login: (user: LoginRequest) => void
  register: (user: RegisterRequest) => void
  registerGuest: (name: string) => void
  isLoggedIn: boolean,
  socialLogin: (user: RegisterRequest) => void
  logout: () => Promise<boolean>
  getUser: (user: LoginRequest) => void
  isGuest: boolean
}

const getLoggedInToken = (): string | null => {
  const authUser = localStorage.getItem('authUser')
  if (authUser != null && authUser?.length > 0) {
    const userObj = JSON.parse(authUser)
    return userObj.token
  }
  return null
}
const getLoggedInUser = (): User | null => {
  const authUser = localStorage.getItem('authUser')
  if (authUser != null && authUser?.length > 0) {
    const userObj = JSON.parse(authUser)
    return userObj.user
  }
  return null
}

const useUserStore = create<State>((set, get): State => ({
// const userStore = (set) => ({
  token: getLoggedInToken(),
  user: getLoggedInUser(),
  isLoggedIn: !!localStorage.getItem("authUser"),
  login: async (user: User) => {
    try {
      const retrievedUser: UserResponse = await service.login(user)
      if (retrievedUser && !retrievedUser.error) {
        localStorage.setItem('authUser', JSON.stringify(retrievedUser))
        set(() => ({
          token: retrievedUser.token,
          user: retrievedUser.user,
          isLoggedIn: true,
        }))
        return true
      } else {
        throw new Error('Failed to get user')
      }
    } catch (error) {
      console.error('Error fetching user:', error)
    }
  },
  register: async (user: RegisterRequest) => {
    try {
      const newUser: UserResponse = await service.register(user)
      if (newUser && newUser.token) {
        localStorage.setItem('authUser', JSON.stringify(newUser))
        set((state: State) => ({
          ssoAuth: false,
          token: newUser.token,
          user: newUser.user,
          isLoggedIn: true,
        }))
        return true
      } else {
        throw new Error('Failed to register')
      }
    } catch (error) {
      console.error('Error on registration:', error)
      return false
    }
  },
  registerGuest: async (name: string) => {
    try {
      const randomNumber = Math.floor(10000 + Math.random() * 90000); // 5-digit random number
      const userModel: RegisterRequest = {
        name,
        email: `momenti${randomNumber}@mail.com`,
        password: "123456q!"
      }
      const newUser: UserResponse = await service.register(userModel)
      if (newUser && newUser.token) {
        localStorage.setItem('authUser', JSON.stringify(newUser))
        set(() => ({
          ssoAuth: false,
          token: newUser.token,
          user: newUser.user,
          isLoggedIn: true,
          isGuest: true
        }))
        return true
      } else {
        throw new Error('Failed to register')
      }
    } catch (error) {
      console.error('Error on registration:', error)
      return false
    }
  },
  socialLogin: async (user: RegisterRequest) => {
    try {
      const newUser = await service.socialLogin(user)
      localStorage.setItem('authUser', JSON.stringify(newUser))
      if (newUser && newUser.token) {
        set((state: State) => ({
          ssoAuth: true,
          token: newUser.token,
          user: newUser.user,
          isLoggedIn: true
        }))
        return true
      } else {
        throw new Error('Failed to register')
      }
    } catch (error) {
      console.error('Error on registration:', error)
      return false
    }
  },
  getUser: async (user: LoginRequest) => {
    try {
      const retrievedUser: UserResponse = await service.login(user)
      localStorage.setItem('authUser', JSON.stringify(retrievedUser))
      if (retrievedUser && !retrievedUser.error) {
        set(() => ({
          token: retrievedUser.token,
          user: retrievedUser,
        }))
        return true
      } else {
        throw new Error('Failed to get user')
      }
    } catch (error) {
      console.error('Error fetching user:', error)
    }
  },
  logout: async (): Promise<boolean> => {
    try {
      const user = get().user;
      if (!user) {
        throw new Error('Failed to logout. No user logged in found.')
      }
      // const { signOut } = useClerk()
      const authUser = localStorage.getItem('authUser')
      if (authUser != null && authUser?.length > 0) {
        localStorage.removeItem('authUser')
      }
      if (user.social_account) {
        // signOut()
      }
      set(() => ({
        token: null,
        user: null,
        isLoggedIn: false,
        isGuest: false,
      }))
      const { setEvent} = useEventStore.getState()
      setEvent(null)
      return true
    } catch (error) {
      console.error('Error fetching user:', error)
      return false
    }
  },
}))

// const useUserStore = create(devtools(userStore))

export default useUserStore
