import React, { ReactElement } from 'react'
import Card from '@mui/material/Card'
import { ButtonProps } from '@mui/material/Button'
import AvatarInfos from './AvatarInfos';
import AvatarButtons from './AvatarButtons';

interface Props {
    src?: string;
    srcSet?: string;
    title?: string;
    subheader?: string;
    topRightComponent?: ReactElement | string;
    buttons: Buttons[]
}
const defaultProps = {
    src: '',
    srcSet: '',
    title: '',
    subheader: '',
    topRightComponent: '',
    buttons: []
}
interface Buttons extends ButtonProps {
    label: string | ReactElement
}
const AvatarDetails: React.FC<Props> = (props: Props = defaultProps) => {
  return (
    <Card sx={{ p: '10px' }}>
        <AvatarInfos
            src={props.src}
            srcSet={props.srcSet}
            title={props.title}
            subheader={props.subheader}
            topRightComponent={props.topRightComponent}
        />
      <AvatarButtons buttons={props.buttons}/>
    </Card>
  )
}

export default AvatarDetails
