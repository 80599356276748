import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import useEventStore from 'Store/eventStore'
import useUserStore from 'Store/userStore'
import Slide from '@mui/material/Slide'
import HeaderMenu from './HeaderMenu'
import SearchIcon from '@mui/icons-material/Search'

interface Props {}
const Header: React.FC<Props> = React.memo(function Header() {
  const event = useEventStore((state) => state.event);
  const { isLoggedIn } = useUserStore()

  return (
    <Slide direction="left" in={true} timeout={500}>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static" color="inherit" sx={{ boxShadow: 'none' }}>
          <Toolbar>
            <HeaderMenu />
            <Typography variant="h6" component="div" sx={{ flexGrow: 1, textAlign: 'center' }}>
              {event ? event.title : null}
            </Typography>
            {isLoggedIn && <SearchIcon sx={{ width: 28, height: 28, marginLeft: 'auto' }} />}
          </Toolbar>
        </AppBar>
      </Box>
    </Slide>
  )
})
export default Header
