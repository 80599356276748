import { create } from 'zustand'
import { Media } from 'Models/post'

type State = {
  isOpen: boolean
  images: Media[]
  index: number
  openLightBox: (data: Data) => void
  closeLightBox: () => void
  prev: () => void
  next: () => void
}
interface Data {
    images: Media[]
    photoIndex: number
}

const useLightBoxStore = create<State>((set, get) => ({
  isOpen: false,
  images: [],
  index: 0,
  openLightBox: (data: Data) => set({ isOpen: true, images: data.images, index: data.photoIndex }),
  closeLightBox: () => set({ isOpen: false, images: [], index: 0 }),
  prev: () => set({ index: (get().index + get().images.length - 1) % get().images.length }),
  next: () => set({ index: (get().index + 1) % get().images.length })
}))

export default useLightBoxStore
