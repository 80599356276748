import React from 'react'
import { OAuthStrategy } from '@clerk/types'
import { useSignUp, useSignIn, SignedIn, useClerk } from '@clerk/clerk-react'
import Button from '@mui/material/Button'
// import GoogleIcon from '@mui/icons-material/Google'
// import FacebookIcon from '@mui/icons-material/Facebook'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useParams } from "react-router-dom";
import { GoogleIcon, FacebookIcon } from 'Components/shared-theme/CustomIcons';


interface Props {}
const OauthSignIn: React.FC<Props> = () => {
  const location = useLocation()
  const { t } = useTranslation()
  const { signUp, setActive } = useSignUp()
  const { signIn } = useSignIn()
  const { signOut } = useClerk()
  const { id } = useParams();

  if (!signUp) return null

  const signInWith = (strategy: OAuthStrategy) => {
    return signUp.authenticateWithRedirect({
      strategy,
      // redirectUrl: `/sign-up/sso-callback${location.search}`,
      redirectUrl: `${id?id:''}/sign-up/sso-callback${location.search}`,
      redirectUrlComplete: '/',
    })
  }

  async function handleSignIn(strategy: OAuthStrategy) {
    if (!signIn || !signUp) return null

    // If the user has an account in your application, but does not yet
    // have an OAuth account connected to it, you can transfer the OAuth
    // account to the existing user account.
    const userExistsButNeedsToSignIn =
      signUp.verifications.externalAccount.status === 'transferable' &&
      signUp.verifications.externalAccount.error?.code === 'external_account_exists'

    if (userExistsButNeedsToSignIn) {
      const res = await signIn.create({ transfer: true })

      if (res.status === 'complete') {
        setActive({
          session: res.createdSessionId,
        })
      }
    }

    // If the user has an OAuth account but does not yet
    // have an account in your app, you can create an account
    // for them using the OAuth information.
    const userNeedsToBeCreated = signIn.firstFactorVerification.status === 'transferable'

    if (userNeedsToBeCreated) {
      const res = await signUp.create({
        transfer: true,
      })

      if (res.status === 'complete') {
        setActive({
          session: res.createdSessionId,
        })
      }
    } else {
      // If the user has an account in your application
      // and has an OAuth account connected to it, you can sign them in.
      signInWith(strategy)
    }
  }

  // Render a button for each supported OAuth provider
  // you want to add to your app. This example uses only Google.
  return (
    <>
      <SignedIn>
        <div onClick={signOut()} />
        Your are logged in
      </SignedIn>
      <Button
        variant="outlined"
        color='secondary'
        startIcon={<GoogleIcon />}
        onClick={() => handleSignIn('oauth_google')}
      >
        {t('login_login_with')} Google
      </Button>
      <Button
        variant="outlined"
        color='secondary'
        startIcon={<FacebookIcon />}
        onClick={() => handleSignIn('oauth_facebook')}
      >
        {t('login_login_with')} Facebook
      </Button>
    </>
  )
}
export default OauthSignIn
