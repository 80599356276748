import React from 'react'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'
import useLightBoxStore from 'Store/lightBoxStore'

interface Props {}
const ImageLightBox: React.FC<Props> = () => {
  const { isOpen, images, index, closeLightBox, prev, next } = useLightBoxStore()

  const handleDownload = () => {
    const link = document.createElement('a')
    link.href = images[index]
    link.download = `image-${index + 1}.jpg`
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return (
    <div>
      {isOpen && (
        <Lightbox
          mainSrc={images[index].urls.full}
          nextSrc={images[(index + 1) % images.length].urls.full}
          prevSrc={images[(index + images.length - 1) % images.length].urls.full}
          onCloseRequest={closeLightBox}
          onMovePrevRequest={prev}
          onMoveNextRequest={next}
          imageTitle={images[index].name}
          imageCaption={images[index].name}
          toolbarButtons={[
            <button onClick={handleDownload} style={{ padding: '10px', cursor: 'pointer' }}>
              ⬇ Download
            </button>
          ]}
        />
      )}
    </div>
  )
}

export default ImageLightBox
