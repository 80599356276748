import { create } from 'zustand'

// Define the state type
interface State {
  darkMode: boolean
  toggleDarkMode: () => void
}

const getInitialMode = () => {
  const settings = localStorage.getItem('settings')
  if (settings) {
    const settingsObj = JSON.parse(settings)
    return settingsObj.darkMode
  }
  return false
}

const useSettingsStore = create<State>((set, get) => ({
  darkMode: getInitialMode(),
  toggleDarkMode: () => {
    const prevDarkMode = get().darkMode

    localStorage.setItem('settings', JSON.stringify({ darkMode: !prevDarkMode }))

    set(() => ({
      darkMode: !prevDarkMode,
    }))
  },
}))

export default useSettingsStore
