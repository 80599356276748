import AvatarDetails from 'Components/AvatarDetails'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import useEventStore from 'Store/eventStore'
import EventsList from 'Widgets/Events'
import Stack from '@mui/material/Stack'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

interface Props {}
const Events: React.FC<Props> = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const events = useEventStore((state) => state.events);
  const event = useEventStore((state) => state.event);
  const setEvent = useEventStore((state) => state.setEvent);
  const unsubscribe = useEventStore((state) => state.unsubscribe);
  const getSubscriptions = useEventStore((state) => state.getSubscriptions);
  const getSubscribers = useEventStore((state) => state.getSubscribers);

  const [selected, setSelected] = React.useState(event)
  const [subscribers, setSubscribers] = React.useState([])


  const handleSeeEvent = (uuid: string) => {
    setEvent(uuid)
    navigate('/dashboard')
  }

  React.useEffect(() => {
    getSubscriptions()
  }, [])

  React.useEffect(() => {
    if (event) {
      handleOnItemSelect(event.id)
    }
  }, [event])

  React.useEffect(() => {
    if (!event && events.length > 0) {
      setSelected(events[0])
    }
  }, [event, events])

  const handleOnItemSelect = async (id: number) => {
    const selectedEvent = events.find((ev) => ev.id === id)
    if (selectedEvent) {
      setSelected(selectedEvent)
      const subs = await getSubscribers(selectedEvent.id)
      setSubscribers(subs)
    }
  }

  const handleDoubleClick = (id: number) => {
    const selectedEvent = events.find((ev) => ev.id === id)
    if (selectedEvent) {
      handleSeeEvent(selectedEvent.unique_id)
    }
  }

  const topRightComponent = React.useMemo(() => <Stack direction="column" spacing={1}>
  <Stack direction="row" spacing={1}>
    <Typography variant='body2'>{t('subscribers')}:</Typography>
    <Typography variant='body2'>{subscribers.length}</Typography>
  </Stack>
  <Stack direction="row" spacing={1}>
    <Typography variant='body2'>{t('uploads')}:</Typography>
    <Typography variant='body2'>-</Typography>
  </Stack>
</Stack>, [subscribers.length])

  return (
    <>
      {selected ? (
        <AvatarDetails
          src={selected.media[0]?.urls?.full ?? ''}
          srcSet={selected.media[0]?.srcset ?? ''}
          title={selected.title}
          subheader={selected.description}
          topRightComponent={topRightComponent}
          buttons={[
            {
              onClick: () => unsubscribe(selected.id),
              variant: 'contained',
              label: t('unsubscribe'),
            },
            {
              onClick: () => handleSeeEvent(selected.unique_id),
              variant: 'contained',
              color: 'secondary',
              label: t('view_event'),
            },
          ]}
        />
      ) : null}
      <EventsList
        onItemSelect={handleOnItemSelect}
        handleDoubleClick={handleDoubleClick}
        selected={selected?.id}
      />
    </>
  )
}

export default Events
