import * as React from 'react'
import Box from '@mui/material/Box'
import Header from 'Components/Header/Header'
import BottomNavigation from 'Components/BottomNavigation'
import FileUpload from 'Components/FileUpload'
import ImageLightBox from 'Components/ImageLightBox'
import Modal from 'Components/Modal/Modal'
import UploadInProgress from 'Components/UploadInProgress';

interface Props {
  children: React.ReactNode
}
const Layout: React.FC<Props> = (props) => {
  return (
    <Box>
      <Header />
      {props.children}
      <BottomNavigation/>
      <FileUpload/>
      <ImageLightBox/>
      <UploadInProgress/>
      <Modal/>
    </Box>
  )
}
export default Layout
