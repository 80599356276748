import * as React from 'react'
import useSettingsStore from '../../Store/settingsStore'
import { useTranslation } from 'react-i18next'
import { Switch, FormControlLabel } from '@mui/material'

interface Props {}
const DarkMode: React.FC<Props> = () => {
  const { darkMode, toggleDarkMode } = useSettingsStore()
  const { t } = useTranslation()

  return (
    <>
      <FormControlLabel
        sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row-reverse', margin: 0 }}
        control={
          <Switch
            color="secondary"
            checked={darkMode}
            onChange={toggleDarkMode}
            name={t('dark_mode')}
          />
        }
        label={t('dark_mode')}
      />
    </>
  )
}
export default DarkMode
