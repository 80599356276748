import * as React from 'react'
import { useClerk } from '@clerk/clerk-react'
import useUserStore from '../../Store/userStore'
import { useLocation } from 'react-router-dom'
import Loader from '../../Components/Loader/Loader'
import { UserResource } from '@clerk/types'
import useNavigateCustom from 'Components/Navigation'
import { useParams } from "react-router-dom";

interface Props {}
const SSOLogin: React.FC<Props> = () => {
  const location = useLocation()
  const { user } = useClerk()
  const { socialLogin } = useUserStore()
  const {navigate} = useNavigateCustom()
  const { id } = useParams();

  React.useEffect(() => {
    if (user) {
      console.log(user)
      handleSocialLogin(user)
    }
  }, [user])

  const handleSocialLogin = async (user: UserResource) => {
    const success = await socialLogin({
      name: user.fullName ?? '',
      email: user.primaryEmailAddress?.emailAddress ?? '',
      password: '123456q!',
      image: user.hasImage ? user.imageUrl : '',
      social_account: user.externalAccounts[0]?.provider ?? ''
    })
    const sp = new URLSearchParams(location.search)
    const redirectTo = sp.get('redirectTo')
    if (success) {
      navigate(`/${id?id:'dashboard'}`)
    } else {
      navigate('/register')
    }
  }
  return <Loader />
}
export default SSOLogin
