import * as React from 'react'
import ImageListItem from '@mui/material/ImageListItem'
import ImageListItemBar from '@mui/material/ImageListItemBar'
import Skeleton from '@mui/material/Skeleton'

function srcset(src: string, srcSet: string, width: number, height: number, rows = 1, cols = 1) {
  const sizes = `${(100 / 10) * cols}vw`
  return {
    src,
    srcset: srcSet,
    sizes: sizes,
  }
}

interface Props {
  id: number
  src: string
  srcSet?: string
  title: string
  author: number
  featured?: boolean
  fullWidth?: boolean
  bottomRightButton?: any
  onItemSelect?: (id: number) => void
  handleDoubleClick?: (id: number) => void
  isSelected?: boolean
}
const defaultProps = {
  id: 1,
  src: '',
  srcSet: '',
  title: '',
  author: 1,
  onItemSelect: () => {},
  handleDoubleClick: () => {},
  isSelected: false,
}
const EventListItem: React.FC<Props> = (props: Props = defaultProps) => {
  const [loading, setLoading] = React.useState(true)
  const [hasError, setHasError] = React.useState(false)

  const gap = 10
  let height = 100
  let cols,
    rows = 1
  if (props.fullWidth) {
    cols = 3
    rows = 3
  } else if (props.featured) {
    cols = 2
    rows = 2
  }
  height = height * rows + gap * (rows - 1)

  return (
    <>
      {loading && (
        <Skeleton
          variant="rounded"
          width={'100%'}
          height={`${height}px`}
          sx={{ gridRowEnd: `span ${rows}`, gridColumnEnd: `span ${cols}` }}
        />
      )}
      {hasError && <span>Error loading image</span>}
      <ImageListItem
        key={props.img}
        cols={cols}
        rows={rows}
        className={props.isSelected ? 'selected' : ''}
        onClick={() => props.onItemSelect(props.id)}
        onDoubleClick={() => props.handleDoubleClick(props.id)}
        sx={{ display: loading ? 'none' : 'flex' }}
      >
        <img
          {...srcset(props.src, props.srcSet, 250, 100, rows, cols)}
          alt={props.title}
          // loading="lazy"
          onLoad={() => setLoading(false)}
        />
        <ImageListItemBar
          sx={{
            background:
              'linear-gradient(to top, rgba(0,0,0,0.7) 0%, ' +
              'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
            padding: '0 10px',
          }}
          title={props.title}
          position="bottom"
          actionIcon={props.bottomRightButton}
          actionPosition="right"
        />
      </ImageListItem>
    </>
  )
}

export default EventListItem
