import { get, post, put, del } from './request'
import { CommentRequest, PostResponse, PostRequest, Post } from 'Models/post'
import { User, LoginRequest, RegisterRequest, UserResponse, SubscribersResponse } from 'Models/user'
import { Event, EventResponse, Highlights } from 'Models/event'

// Auth
export const login = (body: LoginRequest): Promise<UserResponse> => {
  return post('/login', body)
}
export const register = (body: RegisterRequest): Promise<UserResponse> => {
  return post('/register', body)
}
export const socialLogin = (body: LoginRequest): Promise<UserResponse> => {
  return post('/social-login', body)
}

// Event
export const getEvents = (): Promise<EventResponse> => {
  return get('/social-events')
}
export const getEvent = (eventId: string): Promise<Event> => {
  return get('/social-events/getById/' + eventId)
}
export const getHighlights = (eventId: number): Promise<Highlights> => {
  return get('/social-events/' + eventId + '/highlights')
}

// Subscribes
export const getSubscriptions = (): Promise<Event[]> => {
  return get(`/subscriptions`)
}
export const getSubscribers = (eventId: number): Promise<SubscribersResponse> => {
  return get(`/social-events/${eventId}/subscribers`)
}
export const subscribe = (eventId: number): Promise<Event> => {
  return put(`/social-events/${eventId}/subscribe`)
}
export const unsubscribe = (eventId: number): Promise<Event> => {
  return put(`/social-events/${eventId}/unsubscribe`)
}

// Posts
export const getPosts = (params: object): Promise<PostResponse> => {
  return get(`/posts`, params)
}
export const getPost = (postId: number): Promise<PostResponse> => {
  return get('/posts/' + postId)
}
export const addPost = (body: PostRequest): Promise<Post> => {
  return post('/posts', body, { contentType: 'multipart/form-data' })
}
export const deletePost = (postId: number): Promise<PostResponse> => {
  return del(`/posts/${postId}`)
}
export const getComments = (postId: number): Promise<Comment[]> => {
  return get(`/posts/${postId}/comments`)
}
export const addComment = (postId: number, body: CommentRequest): Promise<Comment> => {
  return post(`/posts/${postId}/comment`, body)
}
export const like = (postId: number) => {
  return post(`/like/post/${postId}`)
}

// Media
export const likeMedia = (id: number) => {
  return post(`/like/media/${id}`)
}