import * as React from 'react'
import axios from 'axios'
import { Response } from '../Models/generic'
import useUserStore from '../Store/userStore'
import { GenericResponse, ErrorResponse, AuthErrorResponse } from 'Services/IRequest'

interface AxiosSettings {
  contentType: string
}

const defaultSettings: AxiosSettings = {
  contentType: 'application/json',
}

const api = axios.create({
  baseURL: 'https://cp.momenti.app/api',
  // baseURL: 'http://localhost:8000/api',
  headers: {
    'Content-Type': 'application/json',
  },
})

// Add a request interceptor to include the token
api.interceptors.request.use(
  (config) => {
    const { user, token } = useUserStore.getState()
    if (user != null && token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

const handleResponse = (resp: GenericResponse) => {
  if (!resp.success) {
    alert(resp.message)
    return null
  } else {
    return resp.data
  }
}

const get = async (path: string, params?: object): any => {
  return api
    .get(path, { params })
    .then((resp) => {
      return handleResponse(resp.data)
    })
    .catch((err) => {
      switch (err.response.status) {
        case 401:
          const { removeUser } = useUserStore.getState()
          removeUser()
          return { error: true, unauthenticated: true } as AuthErrorResponse
        default:
          if (err.code === 0) return { error: true, unauthenticated: true } as AuthErrorResponse
          return null
      }
    })
}

const post = async (path: string, payload: object = {}, settings: AxiosSettings = defaultSettings): any  => {
  return api
    .post(path, payload, {
      headers: {
        'Content-Type': settings.contentType,
      },
    })
    .then((resp) => {
      return handleResponse(resp.data)
    })
    .catch((err) => {
      switch (err.response.status) {
        case 401:
          const { removeUser } = useUserStore.getState()
          removeUser()
          return { error: true, unauthenticated: true }
        case 500:
        default:
          return null
      }
    })
}

const put = async (path: string, payload: object = {}, settings: AxiosSettings = defaultSettings): any  => {
  return api
    .put(path, payload, {
      headers: {
        'Content-Type': settings.contentType,
      },
    })
    .then((resp) => {
      return handleResponse(resp.data)
    })
    .catch((err) => {
      return err
    })
}

const del = async (path: string, payload?: object, settings: AxiosSettings = defaultSettings): any  => {
  return api
    .delete(path, {
      headers: {
        'Content-Type': settings.contentType,
      },
    })
    .then((resp) => {
      return handleResponse(resp.data)
    })
    .catch((err) => {
      return err
    })
}

export { get, post, put, del }
