import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { Post, PostRequest, PostResponse } from 'Models/post'
import Pagination from 'Models/pagination'
import service from '../Services'
import useEventStore from 'Store/eventStore'
import useUserStore from 'Store/userStore'

interface State {
  posts: Post[]
  likes: number[]
  addPost: (active: PostRequest) => void
  getPosts: () => void
  getFavoritePosts: () => Post[]
  getMyPosts: () => Post[]
  getPost: (postId: number) => void
  loading: boolean
  setLoading: (flag: boolean) => void
  addingPost: boolean
  setAddingPost: (flag: boolean) => void
  pagination: Pagination
}

// const postStore = (set, get) => ({
const usePostStore = create<State>((set, get): State => ({
  posts: [],
  likes: [],
  addPost: async (body: PostRequest) => {
    try {
      // get().setAddingPost(true)
      set(() => ({addingPost: true}))
      // const { selectedEvent } = useEventStore.getState()
      // const { user } = useUserStore.getState()
      // const body: PostRequest = {
      //   ...post,
      //   creator: user?.name,
      //   social_event_id: selectedEvent,
      // }
      const newPost = await service.addPost(body)
      if (newPost) {
        set((state: State) => ({
          posts: [{ ...newPost }, ...state.posts],
        }))
      } else {
        throw new Error('Failed to add post')
      }
    } catch (error) {
      console.error('Error adding post:', error)
    } finally {
      // get().setAddingPost(false)
      set(() => ({addingPost: false}))
    }
  },
  getPosts: async () => {
    try {
      set({ loading: true });
      const { event } = useEventStore.getState()
      const { user } = useUserStore.getState()
      if (event) {
        const body = { socialEvent: event.id, page: 1 }
        const response: PostResponse = await service.getPosts(body)
        const newPosts: Post[] = response.posts
        if (newPosts) {
          set((state: State) => ({
            posts: newPosts,
            likes: user ? newPosts
              .map((post) => {
                if (post.likes.users.map((u) => u.name).includes(user?.name)) {
                  return post.id
                }
              })
              .filter((p) => p > 0) : [],
            pagination: { meta: response.meta, links: response.links },
          }))
        } else {
          throw new Error('Failed to add post')
        }
      } else {
        throw new Error('No event id provided')
      }
    } catch (error) {
      console.error('Error fetching posts:', error)
    } finally {
      set({ loading: false });
    }
  },
  getPrevPosts: async () => {
    try {
      if (get().pagination) {
        get().setLoading(true)
        const { selectedEvent } = useEventStore.getState()
        const getNextPage = () => {
          const currentPage = get().pagination.meta.current_page
          const totalPages = get().pagination.meta.total_pages
          return currentPage >= totalPages ? -1 : currentPage + 1
        }
        if (selectedEvent) {
          const pageNum = getNextPage()
          if (pageNum > 0) {
            const body = { socialEvent: selectedEvent, page: pageNum }
            const response: Post.PostResponse = await service.getPosts(body)
            const newPosts: Post.Post[] = response.posts
            if (newPosts) {
              set((state: State) => ({
                posts: [...state.posts, ...newPosts],
                pagination: { meta: response.meta, links: response.links },
              }))
            } else {
              throw new Error('Failed to add post')
            }
          } else {
            console.error('No other post available')
          }
        } else {
          throw new Error('No event id provided')
        }
      }
    } catch (error) {
      console.error('Error fetching posts:', error)
    } finally {
      get().setLoading(false)
    }
  },
  getFavoritePosts: async (): Promise<Post.Post[]> => {
    try {
      get().setLoading(true)
      const { user } = useUserStore.getState()
      if (user) {
        const simplePosts = get().posts

        const favoritePosts = simplePosts.filter((p) => get().likes.includes(p.id))
        // const favoritePosts = simplePosts.filter((post: Post.Post) => {
        //   return post.likes.users.map((u) => u.name).includes(user.name)
        // })

        return favoritePosts
      }
      return []
    } catch (error) {
      console.error('Error fetching posts:', error)
      return []
    } finally {
      get().setLoading(false)
    }
  },
  getMyPosts: (): Post.Post[] => {
    try {
      get().setLoading(true)
      const { user } = useUserStore.getState()
      if (user) {
        const simplePosts = get().posts

        const myPosts = simplePosts.filter((post: Post.Post) => {
          return post.creator.name.includes(user.name)
        })

        return myPosts
      }
      return []
    } catch (error) {
      console.error('Error fetching posts:', error)
      return []
    } finally {
      get().setLoading(false)
    }
  },
  getPost: async (postId: number) => {
    try {
      const newPosts: Post.Post = await service.getPost(postId)
      if (newPosts) {
        set(() => ({
          posts: newPosts,
        }))
      } else {
        throw new Error('Failed to add post')
      }
    } catch (error) {
      console.error('Error fetching posts:', error)
    }
  },
  deletePost: async (postId: number) => {
    try {
      const post: Post.Post = await service.deletePost(postId)
      if (post) {
        const posts = [...get().posts]
        const index = posts.findIndex((post: Post.Post) => post.id === postId)
        if (index > -1) {
          posts.splice(index, 1)
        }
        set(() => ({
          posts: posts,
        }))
      } else {
        throw new Error('Failed to add post')
      }
    } catch (error) {
      console.error('Error fetching posts:', error)
    }
  },
  like: async (postId: number) => {
    try {
      const { user } = useUserStore.getState()
      const likesArray = [...get().likes]
      const newLikes: number[] = likesArray
      if (likesArray.includes(postId)) {
        const index = likesArray.findIndex((l) => l == postId)
        likesArray.splice(index, 1)
      } else {
        likesArray.push(postId)
      }

      const likedPost: Post.Post = get().posts.find((p) => p.id === postId)

      let updatedLikes: Post.Likes = likedPost.likes
      if (get().likes.includes(postId)) {
        const usersWithoutMe = likedPost.likes.users.filter((u) => u.name !== user?.name)
        updatedLikes = {
          count: likedPost.likes.count - 1,
          users: usersWithoutMe,
        }
      } else {
        updatedLikes = {
          count: likedPost.likes.count + 1,
          users: [...likedPost.likes.users, { name: user.name }],
        }
      }

      likedPost.likes = updatedLikes

      const posts = get().posts
      const likedPostIdx = posts.findIndex((p) => p.id === postId)
      posts[likedPostIdx] = likedPost

      set(() => ({
        posts: posts,
        likes: newLikes,
      }))
      const updatedPost = await service.like(postId)
      if (updatedPost?.likes_counter >= 0) {
        return updatedPost.likes_counter
      } else {
        throw new Error('Failed to make like')
      }
    } catch (error) {
      console.error('Error making like:', error)
    }
  },
  addComment: async (postId: number, content: string) => {
    try {
      const updatedPost: Post.Post = await service.addComment(postId, { content: content })
      if (updatedPost?.comments) {
        return updatedPost.comments
      } else {
        throw new Error('Failed to add comment')
      }
    } catch (error) {
      console.error('Error adding comment:', error)
    }
  },
  loading: false,
  setLoading: (flag: boolean) => {
    set(() => ({
      loading: flag,
    }))
  },
  addingPost: false,
  setAddingPost: (flag: boolean) => {
    set(() => ({
      addingPost: flag,
    }))
  },
}))

// const usePostStore = create(devtools(postStore))

export default usePostStore
