import React from "react";
import { Navigate, Outlet, useParams } from "react-router-dom";
import useUserStore from 'Store/userStore';
import useEventStore from 'Store/eventStore';
import Loader from "Components/Loader";
import LayoutOffline from 'Components/LayoutOffline/LayoutOffline';



const PublicRoute = () => {
    const { isLoggedIn } = useUserStore();
    const { eventCheckIfExists } = useEventStore();
    const { id } = useParams();
    const [checking, setChecking] = React.useState(id ? true : false);


    const isAuthenticated = () => {
        return isLoggedIn || !!localStorage.getItem("authUser"); // Replace with real auth logic
      };

    React.useEffect(() => {
        if (id) {
            checkEvent(id)
        }
    }, [id]);

    const checkEvent = async (uuid: string) => {
        setChecking(true)
        await eventCheckIfExists(uuid)
        setChecking(false)
    }


    if (checking) return <Loader/>

    if (isAuthenticated()) {
        return <Navigate to={`/dashboard`} replace />; // Redirect to dashboard
    }
    // return <Navigate to={`/signin`} replace />; // Redirect to dashboard

    return <LayoutOffline>
        <Outlet />
    </LayoutOffline>; // Render the child route signin signon etc
};

export default PublicRoute;
