import { create } from "zustand";

interface FileStoreState {
  files: File[];
  setFiles: (files: File[]) => void;
  fileUploadRef: React.RefObject<HTMLInputElement> | null;
  setFileUploadRef: (ref: React.RefObject<HTMLInputElement>) => void;
  triggerFileInput: () => void
}

const useFileStore = create<FileStoreState>((set, get) => ({
  files: [],
  setFiles: (files) => set({ files }),
  fileUploadRef: null,
  setFileUploadRef: (ref) => set({ fileUploadRef: ref }),
  triggerFileInput: () => {
    const ref = get().fileUploadRef;
    if (ref?.current) {
      ref.current.click(); // Triggers file selection
    }
  },
}));

export default useFileStore