import React, { useRef, useEffect } from "react";
import useFileStore from "Store/fileStore";
import Preview from "Widgets/Preview/Preview";

const FileUpload: React.FC = () => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const {setFiles, setFileUploadRef} = useFileStore();

  useEffect(() => {
    if (fileInputRef.current) {
      setFileUploadRef(fileInputRef);
    }
  }, [setFileUploadRef]);

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setFiles(Array.from(event.target.files));
    }
  };

  return (
    <>
    <input
      type="file"
      multiple
      ref={fileInputRef}
      style={{ display: "none" }}
      onChange={handleFileSelect}
    />
        <Preview/>
    </>
  );
};

export default FileUpload;
