import React, { ReactElement } from 'react'
import CardActions from '@mui/material/CardActions'
import Button from '@mui/material/Button'
import { ButtonProps } from '@mui/material/Button'

interface Props {
    buttons: Buttons[]
}
interface Buttons extends ButtonProps {
    label: string | ReactElement
}
const AvatarButtons: React.FC<Props> = React.memo(function AvatarButtons(props: Props) {
  return (
    <CardActions sx={{ justifyContent: 'end' }}>
        {
            props.buttons && props.buttons.map((btn, idx) => 
                <Button key={idx} {...btn}>
                  {btn.label}
                </Button>
            )
        }
      </CardActions>
  )
})

export default AvatarButtons
