import * as React from 'react'
import EventDetails from 'Components/EventDetails/EventDetails';
import SignUp from 'Components/SignUp'

interface Props {}
const Register: React.FC<Props> = () => {

  return <>
      <SignUp />
      <EventDetails />
    </>;
}
export default Register
