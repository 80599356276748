import * as React from 'react'
import useEventStore from 'Store/eventStore'
import { Container } from '@mui/material'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import ImageList from 'Components/ImageList'

const itemData2 = [
  {
    id: 1,
    img: 'https://images.unsplash.com/photo-1551963831-b3b1ca40c98e',
    title: 'Breakfast',
    author: '@bkristastucchio',
    featured: true,
  },
  {
    id: 2,
    img: 'https://images.unsplash.com/photo-1551782450-a2132b4ba21d',
    title: 'Burger',
    author: '@rollelflex_graphy726',
  },
  {
    id: 3,
    img: 'https://images.unsplash.com/photo-1522770179533-24471fcdba45',
    title: 'Camera',
    author: '@helloimnik',
  },
  {
    id: 4,
    img: 'https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c',
    title: 'Coffee',
    author: '@nolanissac',
  },
  {
    id: 5,
    img: 'https://images.unsplash.com/photo-1533827432537-70133748f5c8',
    title: 'Hats',
    author: '@hjrc33',
  },
  {
    id: 6,
    img: 'https://images.unsplash.com/photo-1558642452-9d2a7deb7f62',
    title: 'Honey',
    author: '@arwinneil',
    featured: true,
  },
  {
    id: 7,
    img: 'https://images.unsplash.com/photo-1516802273409-68526ee1bdd6',
    title: 'Basketball',
    author: '@tjdragotta',
  },
  {
    id: 8,
    img: 'https://images.unsplash.com/photo-1518756131217-31eb79b20e8f',
    title: 'Fern',
    author: '@katie_wasserman',
  },
  {
    id: 9,
    img: 'https://images.unsplash.com/photo-1597645587822-e99fa5d45d25',
    title: 'Mushrooms',
    author: '@silverdalex',
  },
  {
    id: 10,
    img: 'https://images.unsplash.com/photo-1567306301408-9b74779a11af',
    title: 'Tomato basil',
    author: '@shelleypauls',
  },
  {
    id: 11,
    img: 'https://images.unsplash.com/photo-1471357674240-e1a485acb3e1',
    title: 'Sea star',
    author: '@peterlaster',
  },
  {
    id: 12,
    img: 'https://images.unsplash.com/photo-1589118949245-7d38baf380d6',
    title: 'Bike',
    author: '@southside_customs',
  },
]

interface Props {
  onItemSelect: (id: number) => void
  handleDoubleClick?: (id: number) => void
  selected?: number
}
const EventList: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()
  const { events } = useEventStore()

  if (!events) return null
  const itemData = events.map((e) => {
    return {
      id: e.id,
      src: e.media[0].urls.full,
      srcSet: e.media[0].srcset,
      title: e.title,
      author: e.user_id,
      isSelected: props.selected === e.id,
      //   featured: true,
    }
  })

  return (
    <>
      {
        React.useMemo(() => <Container>
            <Stack direction="row" spacing={1} sx={{ justifyContent: 'space-between' }}>
            <Typography variant="body2">{t('nav_my_events')}</Typography>
            <Typography variant="body2">{events.length}</Typography>
            </Stack>
        </Container>
        , [events.length])
      }
      <ImageList
        items={itemData}
        onItemSelect={props.onItemSelect}
        handleDoubleClick={props.handleDoubleClick}
      />
    </>
  )
}

export default EventList
