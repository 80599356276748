import { ReactElement } from 'react'
import { create } from 'zustand'
import { ButtonPropsExtended } from 'Components/Modal/Modal'

type State = {
  isOpen: boolean
  content: Content
  openModal: (content: Content) => any
  closeModal: () => void
}

interface Content {
  title?: string | ReactElement
  message?: string | ReactElement
  buttons: ButtonPropsExtended[]
}

const useModalStore = create<State>((set) => ({
  isOpen: false,
  content: {
    title: '',
    message: '',
    buttons: [],
  },
  buttons: [],
  openModal: (content) => set({ isOpen: true, content }),
  closeModal: () => set({ isOpen: false }),
}))

export default useModalStore
