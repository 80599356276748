import React from 'react'
import EventDetails from 'Components/EventDetails/EventDetails';
import SignIn from 'Components/SignIn'

interface Props {}
const Login: React.FC<Props> = () => {

  return <>
      <SignIn />
      <EventDetails />
    </>;
}

export default Login
