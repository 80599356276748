import * as React from 'react'
import EventBanner from 'Widgets/EventBanner'
import Posts from 'Widgets/Posts'
import useEventStore from 'Store/eventStore'
import Loader from 'Components/Loader'
import AvatarDetails from 'Components/AvatarDetails'
import Stack from '@mui/material/Stack'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import useFileStore from 'Store/fileStore'
import usePostStore from 'Store/postStore'

export default function Dashboard() {
  const { t } = useTranslation()
  const { triggerFileInput } = useFileStore()

  const loading = useEventStore((state) => state.loading);
  const event = useEventStore((state) => state.event);
  const unsubscribe = useEventStore((state) => state.unsubscribe);
  const getSubscriptions = useEventStore((state) => state.getSubscriptions);
  const getSubscribers = useEventStore((state) => state.getSubscribers);
  const setEvent = useEventStore((state) => state.setEvent);
  const posts = usePostStore((state) => state.posts);

  const [subscribers, setSubscribers] = React.useState([])

  React.useEffect(() => {
    // if (!loading) {
    fetchData()
    // }
  }, [])

  React.useEffect(() => {
    if (event) {
        getSubscribersAsync(event)
    }
  }, [event])

  const getSubscribersAsync = async (event) => {
    const subs = await getSubscribers(event.id)
    setSubscribers(subs)
  }

  const fetchData = async () => {
    if (event) {
      //   fetchEventData(event.unique_id)
    } else {
      const subs = await getSubscriptions()
      if (subs.length > 0) {
        await setEvent(subs[0].unique_id)
        // fetchEventData(subs[0].unique_id)
      }
    }
  }

  if (loading) return <Loader />
  if (!event) return null

  const topRightComponent = (
    <Stack direction="column" spacing={1}>
      <Stack direction="row" spacing={1}>
        <Typography variant='body2'>{t('subscribers')}:</Typography>
        <Typography variant='body2'>{subscribers.length}</Typography>
      </Stack>
      <Stack direction="row" spacing={1}>
        <Typography variant='body2'>{t('uploads')}:</Typography>
        <Typography variant='body2'>{posts.length}</Typography>
      </Stack>
    </Stack>
  );
  return (
    <div>
      <EventBanner />
      <AvatarDetails
        src={event.media[0]?.urls?.full ?? ''}
        srcSet={event.media[0]?.srcset ?? ''}
        title={event.title}
        subheader={event.description}
        topRightComponent={topRightComponent}
        buttons={[
          {
            onClick: () => unsubscribe(event.id),
            variant: 'contained',
            label: t('unsubscribe'),
          },
          {
            onClick: () => triggerFileInput(),
            variant: 'contained',
            color: 'secondary',
            label: t('upload'),
          },
        ]}
      />
      <Posts />
    </div>
  )
}
