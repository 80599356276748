import React, { useEffect } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import CardMedia from '@mui/material/CardMedia'
import useEventStore from '../../Store/eventStore'
import Moment from '../Moment/Moment'
import { CardContent, Grid } from '@mui/material'
import SwipeableEdgeDrawer from '../SwipeableDrawer/SwipeableDrawer'
import Skeleton from '@mui/material/Skeleton'
import HeaderMenu from '../Header/HeaderMenu'
import { Event } from '../../Models/event'
import { useParams } from 'react-router-dom'

interface Props {
  // event: Event
}

const EventDetails: React.FC<Props> = () => {
  const { id } = useParams()
  const { event, getEvent } = useEventStore()

  useEffect(() => {
    if (id) {
      getEvent(id)
    }
  }, [])

  if (!event) {
    return null
  }

  return (
    <Card
      sx={{
        width: '100%',
        height: '100%',
        borderRadius: 0,
        position: 'absolute',
        zIndex: '-1',
        top: 0,
      }}
    >
      <Box sx={{ height: '100%' }}>
        <CardMedia
          component="img"
          sx={{ height: '100%' }}
          src={event.media[0]?.urls.full}
          srcSet={event.media[0]?.srcset}
        />
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            bgcolor: 'rgba(0, 0, 0, 0.54)',
            color: 'white',
            padding: '5px 10px',
          }}
        >
          {/* <Grid container sx={{height: '100%'}}>
                <Grid xs={10} md={10}>
                  <Typography variant="h5" sx={{textAlign: 'left'}}>{event.title}</Typography>
                </Grid>
                <Grid xs={2} md={2}>
                  <HeaderMenu />
                </Grid>
                <Grid xs={9} md={9}>
                  <Typography variant="body2" sx={{textAlign: 'left'}}>
                    <div dangerouslySetInnerHTML={{ __html: event.description }}></div>
                  </Typography>
                </Grid>
                <Grid xs={3} md={3} sx={{ marginLeft: 'auto', alignContent: 'center' }}>
                  <Typography variant="body2">
                    <Moment format="fromNow" date={event.date} />
                  </Typography>
                </Grid>
              </Grid> */}
        </Box>
      </Box>
    </Card>
  )
  return (
    //   <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
    <Card
      sx={{
        width: '100%',
        height: '100%',
        m: '10px',
        borderRadius: 0,
        position: 'absolute',
        zIndex: '-1',
        top: 0,
      }}
      // onClick={() => setShowEventDetails(!showEventDetails)}
    >
      <Box sx={{ position: 'relative' }}>
        <CardMedia
          component="img"
          sx={{ height: 400 }}
          //   src={event.media[0]?.urls.full}
          //   srcSet={event.media[0]?.srcset}
          src="https://images.unsplash.com/photo-1512917774080-9991f1c4c750?auto=format&w=350&dpr=2"
        />
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            bgcolor: 'rgba(0, 0, 0, 0.54)',
            color: 'white',
            padding: '5px 10px',
          }}
        >
          <Grid container sx={{ height: '100%' }}>
            <Grid xs={10} md={10}>
              <Typography variant="h5" sx={{ textAlign: 'left' }}>
                {event.title}
              </Typography>
            </Grid>
            <Grid xs={2} md={2}>
              <HeaderMenu />
            </Grid>
            <Grid xs={9} md={9}>
              <Typography variant="body2" sx={{ textAlign: 'left' }}>
                <div dangerouslySetInnerHTML={{ __html: event.description }}></div>
              </Typography>
            </Grid>
            <Grid xs={3} md={3} sx={{ marginLeft: 'auto', alignContent: 'center' }}>
              <Typography variant="body2">
                <Moment format="fromNow" date={event.date} />
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Card>
    //   </Box>
  )
}

export default EventDetails
