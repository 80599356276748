import React from 'react'
import useEventStore from 'Store/eventStore'
import Box from '@mui/material/Box'
import ImageList from 'Components/ImageList'
import useLightBoxStore from 'Store/lightBoxStore'

interface Props {}
const EventBanner: React.FC<Props> = () => {
  const event = useEventStore((state) => state.event);
  const openLightBox = useLightBoxStore((state) => state.openLightBox)

  if (!event) return null

  const medias = []
  event.media.forEach((m, idx) => {
    medias.push({
      id: m.id,
      src: m.urls.full,
      srcSet: m.srcset,
      title: m.name,
      author: event.title,
      featured: idx < 1 || m.likes.count,
      fullWidth: event.media.length < 2,
    })
  })

  const handleOnItemSelect = (id: number) => {
    const idx = medias.findIndex((m) => m.id === id)
    if (idx >= 0) {
      openLightBox({ images: medias.map((m) => m.src), photoIndex: idx })
    }
  }

  return <ImageList items={medias} onItemSelect={handleOnItemSelect} />
  return (
    <Box
      component="img"
      sx={{
        height: '28vh',
        width: '100%',
        objectFit: 'cover',
        borderRadius: '10px 10px 0 0',
      }}
      alt={event.media[0]?.name}
      src={event.media[0]?.urls.full}
      srcSet={event.media[0]?.srcset}
    />
  )
}

export default EventBanner
