import * as React from 'react'
import usePostStore from 'Store/postStore'
import useModalStore from 'Store/modalStore'
import Loader from 'Components/Loader'
import Box from '@mui/material/Box'
import ProgressBar from 'Components/ProgressBar/ProgressBar'

interface Props {}
const UploadInProgress: React.FC<Props> = () => {
  const { addingPost } = usePostStore()
  const { openModal, closeModal } = useModalStore()


  React.useEffect(() => {
    if (addingPost) {
      openModal({
        message: (
          <Box sx={{ width: '60vw', justifyItems: 'center' }}>
            <Loader />
            <br/>
            <ProgressBar completed={false}/>
          </Box>
        ),
        buttons: [],
      })
    } else {
      closeModal()
    }
  }, [addingPost])

  return null
}
export default UploadInProgress
