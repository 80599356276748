import React from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material'
import { ButtonProps } from '@mui/material/Button'
import useModalStore from 'Store/modalStore'
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export interface ButtonPropsExtended extends ButtonProps {
  label: string
}

const Modal = () => {
  const { isOpen, content, closeModal } = useModalStore()
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));


  return (
    <Dialog open={isOpen} onClose={closeModal}>
      <DialogTitle>{content.title}</DialogTitle>
      <DialogContent>{content.message}</DialogContent>
      <DialogActions>
        {content.buttons.map((btn: ButtonPropsExtended, idx: number) => (
          <Button key={idx} {...btn} >
            {btn.label}
          </Button>
        ))}
      </DialogActions>
    </Dialog>
  )
}

export default Modal
