import { create } from 'zustand'
// import { devtools } from 'zustand/middleware'
import { Event } from 'Models/event'
import { Post } from 'Models/post'
import { User } from 'Models/user'
import Pagination from 'Models/pagination'
import service from 'Services'
import usePostStore from 'Store/postStore'
import useModalStore from 'Store/modalStore'
import i18next from "i18next";

interface State {
  uuid: string
  setUuid: (uuid: string) => void
  events: Event[],
  event: Event | null,
  setEvent: (uuid: string | null) => void
  selectedEvent: Event | null
  addEvent: (event: Event) => void
  eventExist: (uuid: string | null | undefined) => Promise<boolean>
  eventCheckIfExists: (uuid: string) => Promise<boolean>
  getEvent: (eventId: string) => Promise<Event | null>
  getHighlights: (eventId: number) => Promise<Post[]>
  getSubscribers: (id: number) => Promise<User[]>
  getSubscriptions: () => Promise<Event[]>
  isSubscribed: (uuid: string) => boolean
  subscribe: (id: number) => Promise<boolean>
  unsubscribe: (id: number) => Promise<boolean>
  loading: boolean
  setLoading: (flag: boolean) => void
  pagination: Pagination | null,
  fetchEventData: (uuid: string) => Promise<void>
  error: string | null;
}


const useEventStore = create<State>((set, get): State => ({
  uuid: '',
  events: [],
  event: null,
  setEvent: async (uuid: string | null) => {
    set(() => ({ loading: true }))
    let event: Event | null = null
    if (!uuid) {
      set(() => ({ event }))
    } else {
      event = await get().getEvent(uuid)
      if (event) {
        set(() => ({ event }))
      } else {
        set(() => ({ error: 'Could not find event' }))
      }
    }
    set(() => ({ loading: false }))
    return event
  },
  error: null,
  setUuid: (uuid: string) => {
    set(() => ({
      uuid,
      loading: true
    }))
  },
  eventExist: async (uuid: string | undefined | null): Promise<boolean> => {
    if (!uuid) return new Promise((reject) => reject)
    try {
      const response = await get().getEvent(uuid)
      if (response) {
        return true
      } else {
        throw new Error('Failed to get events')
      }
    } catch (error) {
      console.error('Error fetching events:', error)
      return false
    }
  },
  eventCheckIfExists: async (uuid: string): Promise<boolean> => {
    set(() => ({ loading: true }))
    try {
      const response = await get().getEvent(uuid)
      if (response) {
        set(() => ({
          event: response
        }))
        return true
      } else {
        throw new Error('Failed to get events')
      }
    } catch (error) {
      console.error('Error fetching events:', error)
      set(() => ({ loading: false }))
      return false
    } finally {
      set(() => ({ loading: false }))
    }
  },
  getEvent: async (uuid: string): Promise<Event | null> => {
    try {
      const retrievedEvent = await service.getEvent(uuid)
      if (retrievedEvent) {
        set(() => ({
          event: retrievedEvent
        }))
        return retrievedEvent
      } else {
        throw new Error('Failed to retrieve event')
      }
    } catch (error) {
      console.error('Error fetching event:', error)
      return null
    }
  },
  getHighlights: async (eventId: number): Promise<Post[]> => {
    try {
      if (!eventId) {
        throw new Error('event id not provided')
      }
      get().setLoading(true)
      const retrievedEvent = await service.getHighlights(eventId)
      if (retrievedEvent) {
        return retrievedEvent.posts.posts
      } else {
        throw new Error('Failed to retrieve highlights')
      }
    } catch (error) {
      console.error('Error fetching highlights:', error)
      return []
    } finally {
      get().setLoading(false)
    }
  },
  getSubscribers: async (id: number): Promise<User[]> => {
    set(() => ({ loading: true }))
    try {
      const resp = await service.getSubscribers(id)
      if (resp) {
        return resp.users
      } else {
        throw new Error('Failed to retrieve highlights')
      }
    } catch (error) {
      console.error('Error fetching subscribers for this event:', error)
      return []
    } finally {
      set(() => ({ loading: false }))
    }
  },
  getSubscriptions: async (): Promise<Event[]> => {
    try {
      // get().setLoading(true)
      set(() => ({ loading: true }))
      const retrievedEvents = await service.getSubscriptions()
      if (retrievedEvents) {
        set(() => ({
          events: retrievedEvents,
        }))
        return retrievedEvents
      } else {
        throw new Error('Failed to retrieve event subscriptions')
      }
    } catch (error) {
      console.error('Error fetching event subscriptions:', error)
      return []
    } finally {
      set(() => ({ loading: false }))
    }
  },
  isSubscribed: (uuid: string): boolean => {
    return get().events.some((sub => sub.unique_id === uuid))
  },
  subscribe: async (id: number): Promise<boolean> => {
    try {
      const retrievedEvent: Event = await service.subscribe(id)
      if (retrievedEvent) {
        return true
      } else {
        throw new Error('Failed to subscribe event')
      }
    } catch (error) {
      console.error('Error subscribing event:', error)
      return false
    }
  },
  unsubscribe: async (id: number): Promise<boolean> => {
    const action = async () => {
      try {
        set(() => ({ loading: true }))
        const retrievedEvent = await service.unsubscribe(id)
        if (retrievedEvent) {
          set((state: State) => ({
            events: state.events.filter((e) => e.id !== id)
          }))
          if (get().event?.id === id) {
            set(() => ({
              event: null
            }))
          }
          return true
        } else {
          throw new Error('Failed to unsubscribe event')
        }
      } catch (error) {
        console.error('Error unsubscribing event:', error)
        return false
      } finally {
        set(() => ({ loading: false }))
      }
    }
    const {openModal, closeModal} = useModalStore.getState()
    return openModal(
      {
        title: i18next.t('modal_unsubscribe_confirm_title'),
        message: i18next.t('modal_unsubscribe_confirm_text'),
        buttons: [
          {
            label: i18next.t('modal_unsubscribe_confirm_cancel'),
            color: "secondary",
            onClick: () => {closeModal()}
          },
          {
            label: i18next.t('modal_unsubscribe_confirm_confirm'),
            color: "primary",
            variant: "contained",
            onClick: async () => {
              closeModal()
              return action()
            }
          }
        ]
      }
    )
  },
  loading: false,
  setLoading: (flag: boolean) => {
    set(() => ({
      loading: flag,
    }))
  },
  fetchEventData: async () => {
    set({ loading: true, error: null })
    try {
      // const eventData = await service.getEvent(uuid)
      // set({ event: eventData });
      await usePostStore.getState().getPosts()
    } catch (error) {
      set({ error: 'Error fetching data.' })
    } finally {
      set({ loading: false })
    }
  },
  selectedEvent: null,
  addEvent: function (): void {
    throw new Error('Function not implemented.')
  },
  pagination: null
}))

// const useEventStore = create(devtools(eventStore))

export default useEventStore
