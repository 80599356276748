import * as React from 'react'
import usePostStore from 'Store/postStore'
import useLightBoxStore from 'Store/lightBoxStore'
import Loader from 'Components/Loader'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import ImageList from 'Components/ImageList'
import Like from 'Widgets/Like'
import { useTranslation } from 'react-i18next'

interface Props {}
const Posts: React.FC<Props> = React.memo(function Posts() {
  const { t } = useTranslation()
  const loading = usePostStore((state) => state.loading);
  const getPosts = usePostStore((state) => state.getPosts);
  const posts = usePostStore((state) => state.posts);
  const openLightBox = useLightBoxStore((state) => state.openLightBox);

  React.useEffect(() => {
    getPosts()
  }, [])

  if (loading) return <Loader />

  const medias = []
  const images = []
  posts.map((post) => {
    if (post.media.length > 0) {
      post.media.forEach((m, idx) => {
        images.push(m)
        medias.push({
          id: m.id,
          src: m.urls.full,
          srcSet: m.srcset,
          title: post.title,
          author: post.creator,
          featured: idx < 1 || m.likes.count,
          fullWidth: post.media.length < 2,
          bottomRightButton: <Like id={m.id} likes={m.likes} />,
        })
      })
    }
  })

  const handleOnItemSelect = (id: number) => {
    const idx = medias.findIndex((m) => m.id === id)
    if (idx >= 0) {
      openLightBox({images: images, photoIndex: idx })
    }
  }

  return (
    <>
      <Container>
        <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
          <Typography variant="body2">{t('contents')}</Typography>
          <Typography variant="body2">{medias.length} {t('items')}</Typography>
        </Stack>
      </Container>
      <ImageList items={medias} onItemSelect={handleOnItemSelect} />
    </>
  )
})

export default Posts
