import * as React from 'react'
import useFileStore from 'Store/fileStore'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import ImageList from 'Components/ImageList'
import useModalStore from 'Store/modalStore'
import usePostStore from 'Store/postStore'
import useUserStore from 'Store/userStore'
import useEventStore from 'Store/eventStore'
import { useTranslation } from 'react-i18next'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}

interface Props {}
const Preview: React.FC<Props> = () => {
  const { files, setFiles } = useFileStore()
  const { openModal, closeModal } = useModalStore()
  const { addPost } = usePostStore()
  const { user } = useUserStore()
  const { event } = useEventStore()
  const { t } = useTranslation()

  const getContent = (medias) => {
    return (
    //   <Box sx={style}>
    <>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {t('modal_preview_confirm_text')}
        </Typography>
        <ImageList
          items={medias}
          // onItemSelect={props.onItemSelect}
        />
    </>
    )
  }

  React.useEffect(() => {
    if (files.length > 0) {

        const medias: any[] | (() => any[]) = []
        files.map((file, idx) => {
            medias.push({
            id: idx,
            src: file.type.startsWith('image/') ? URL.createObjectURL(file) : file.name,
            title: file.name,
            // author: post.creator,
            // featured: idx < 1 || m.likes.count,
            fullWidth: files.length < 2,
            // bottomRightButton: <Like id={m.id} likes={m.likes} />,
            })
        });

      openModal({
        title: t('modal_preview_confirm_title'),
        message: getContent(medias),
        buttons: [
          {
            label: t('modal_preview_confirm_cancel'),
            color: 'secondary',
            onClick: () => {
                setFiles([])
                closeModal()
            },
          },
          {
            label: t('modal_preview_confirm_confirm'),
            color: 'primary',
            variant: 'contained',
            onClick: () => {
                closeModal()
                if (user && event) {
                    addPost({
                        title: '',
                        content: '',
                        creator: user.id,
                        social_event_id: event.id,
                        media: files
                    })
                }
            },
          },
        ],
      })
    }
  }, [files])

  return null
}

export default Preview
