import Layout from "Components/Layout/Layout";
import React from "react";
import { Navigate, Outlet, useParams } from "react-router-dom";
import useUserStore from 'Store/userStore';
import useEventStore from 'Store/eventStore';

const isAuthenticated = () => {
  const { isLoggedIn } = useUserStore();
  return isLoggedIn || !!localStorage.getItem("authUser"); // Replace with real auth logic
};

const ProtectedRoute = () => {
  const { id } = useParams();
  const event = useEventStore((state) => state.event);
  const isSubscribed = useEventStore((state) => state.isSubscribed);
  const subscribe = useEventStore((state) => state.subscribe);
  
  React.useEffect(() => {
    if (event) {
        if (!isSubscribed(event.unique_id)) {
            subscribe(event.id)
        }
    }
  }, [event]);

  if (!isAuthenticated()) {
    return <Navigate to={`/signin`} replace />;
    return <Navigate to={`/${id}/signin`} replace />;
  } 
  // else if (!event) {
  //   return <Loader />
  // }

  return <Layout>
    <Outlet />
  </Layout>;
};

export default ProtectedRoute;
