import * as React from 'react'
import IconButton from '@mui/material/IconButton'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import { useClerk } from '@clerk/clerk-react'
import { useTranslation } from 'react-i18next'
import useUserStore from '../../Store/userStore'
import useModalStore from 'Store/modalStore'
import { useLocation } from 'react-router-dom'
import useNavigateCustom from 'Components/Navigation'
import MenuIcon from '@mui/icons-material/Menu'

interface Props {}
const HeaderMenu: React.FC<Props> = () => {
  const { t } = useTranslation()
  const { navigate } = useNavigateCustom()
  const location = useLocation()
  const { user, logout } = useUserStore()
  const { signOut } = useClerk()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const { openModal, closeModal } = useModalStore()

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const clickHome = () => {
    handleClose()
    navigate(`/dashboard`)
  }

  const clickEventsList = () => {
    handleClose()
    navigate(`/events${location.search}`)
  }

  const clickProfile = () => {
    handleClose()
    navigate(`/profile${location.search}`)
  }
  const clickSettings = () => {
    handleClose()
    navigate(`/settings${location.search}`)
  }
  const clickLogout = async () => {
    openModal(
      {
        title: t('modal_logout_confirm_title'),
        message: t('modal_logout_confirm_text'),
        buttons: [
          {
            label: t('modal_logout_confirm_cancel'),
            color: "secondary",
            onClick: () => {closeModal()}
          },
          {
            label: t('modal_logout_confirm_confirm'),
            color: "primary",
            variant: "contained",
            onClick: async () => {
              closeModal()
              handleClose()
              await logout()
              signOut({ redirectUrl: '/signin' })
            }
          }
        ]
      }
    )
  }
  return (
    <div>
      <IconButton
        size="large"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
        sx={{ padding: 0 }}
      >
        <MenuIcon sx={{ width: 28, height: 28 }} />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {!!user && <MenuItem onClick={clickHome}>{t('nav_home')}</MenuItem>}
        {!!user && <MenuItem onClick={clickEventsList}>{t('event_list')}</MenuItem>}
        {!!user && <MenuItem onClick={clickProfile}>{t('profile')}</MenuItem>}
        <MenuItem onClick={clickSettings}>{t('settings')}</MenuItem>
        {!!user && <MenuItem onClick={clickLogout}>{t('logout')}</MenuItem>}
      </Menu>
    </div>
  )
}
export default HeaderMenu
