import * as React from 'react'
import useUserStore from 'Store/userStore'
import { Badge, Stack } from '@mui/material'
import AvatarDetails from 'Components/AvatarDetails'
import OauthSignIn from 'Pages/Auth/OauthSignIn'

interface Props {}
const Profile: React.FC<Props> = () => {
  const { user } = useUserStore()

  if (!user) return null

  return (
    <>
      <AvatarDetails
        src={user.image}
        title={user.name}
        subheader={user.email}
        topRightComponent={user.social_account ? <Badge>{user.social_account}</Badge> : ''}
        buttons={[]}
      />
      <Stack>{user.social_account ? <Badge>{user.social_account}</Badge> : <OauthSignIn />}</Stack>
    </>
  )
}
export default Profile
