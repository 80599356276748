import { create } from 'zustand'
import { User } from 'Models/user'
import service from 'Services'
import useUserStore from 'Store/userStore'

interface State {
  like: (id: number) => Promise<number>
  liked: (users: User[]) => boolean
}

const useMediaStore = create<State>((): State => ({
  liked: (users: User[]) => {
    const userId = useUserStore.getState().user?.id;
    if (userId) {
      return users.map((u) => u.id).includes(userId)
    }
    return false;
  },
  like: async (id: number): Promise<number> => {
    try {
      const newMedia = await service.likeMedia(id)
      if (newMedia) {
          return newMedia.likes_counter;
      } else {
        throw new Error('Failed to like media')
      }
    } catch (error) {
      console.error('Error like media:', error)
    }
  }
}))

export default useMediaStore
