import React, { ReactElement } from 'react'
import CardHeader from '@mui/material/CardHeader'
import Avatar from '@mui/material/Avatar'
import Stack from '@mui/material/Stack'
import { gray } from 'Components/shared-theme/themePrimitives'

interface Props {
    src?: string;
    srcSet?: string;
    title?: string;
    subheader?: string;
    topRightComponent?: ReactElement | string;
}
const AvatarInfos: React.FC<Props> = React.memo(function AvatarInfos(props: Props) {

  return (
      <Stack direction="row">
        <CardHeader
          sx={{ width: '100%' }}
          avatar={
            <Avatar sx={{ bgcolor: gray[500], width: 56, height: 56 }} src={props.src} srcSet={props.srcSet}/>
          }
          action={props.topRightComponent}
          titleTypographyProps={{variant:"body1"}}
          title={props.title}
          subheaderTypographyProps={{variant:"body2"}}
          subheader={
            <div dangerouslySetInnerHTML={{ __html: props.subheader ? props.subheader : '' }}></div>
          }
        />
      </Stack>
  )
})

export default AvatarInfos
