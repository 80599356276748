import * as React from 'react'
import Badge from '@mui/material/Badge'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import FavoriteOutlinedIcon from '@mui/icons-material/FavoriteOutlined'
import { Likes } from 'Models/post'
import useMediaStore from 'Store/mediaStore'

interface Props {
  id: number
  likes: Likes
}

const LikesView: React.FC<Props> = (props) => {
  const { like, liked } = useMediaStore()
  const [totalLikes, setTotalLikes] = React.useState(props.likes.count)
  const [likes] = React.useState(props.likes.users)


  const hasMyLikeFnc = () => {
    return liked(likes)
  }

  const [hasMyLike, setHasMyLike] = React.useState(hasMyLikeFnc())

  const makeLike = async (e) => {
    e.preventDefault()
    e.stopPropagation()
    const totalLikes = await like(props.id)
    setTotalLikes(totalLikes)
    setHasMyLike(!hasMyLike)
  }

  return (
    <Badge badgeContent={totalLikes} onClick={makeLike}>
      {hasMyLike ? <FavoriteOutlinedIcon sx={{color: 'red'}}/> : <FavoriteBorderIcon sx={{color: 'white'}}/>}
    </Badge>
  )
}

export default LikesView
