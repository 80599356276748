import * as React from 'react'
import { ThemeProvider, createTheme} from '@mui/material'
import Login from 'Pages/Auth/Login'
import Register from 'Pages/Auth/Register'
import RegisterGuest from 'Pages/Auth/RegisterGuest'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import SSOCallback from 'Pages/Auth/SSOCallback'
import Profile from 'Pages/Profile'
import SSOLogin from 'Pages/Auth/SSOLogin'
import Events from 'Pages/Events'
import Settings from 'Pages/Settings'
import useSettingsStore from 'Store/settingsStore'
import AkaAcidBloggerOtf from './assets/ACBloggerRegular.otf'
import AkaAcidBloggerTtf from './assets/ACBloggerRegular.ttf'
import ProtectedRoute from 'Components/ProtectedRoute';
import PublicRoute from 'Components/PublicRoute';
import Dashboard from 'Pages/Dashboard'
import LandingPage from 'Pages/LandingPage/LandingPage'

export default function App() {
  const { darkMode } = useSettingsStore()

  const theme = createTheme({
    typography: {
      fontSize: 13,
      fontFamily: ['AkaAcidBlogger', 'Arial', 'Helvetica', 'sans-serif'].join(','),
      h1: {
        fontSize: "2.5rem",
        fontWeight: 700
      },
      h2: {
        fontSize: "2rem",
        fontWeight: 600
      },
      h3: {
        fontSize: "1.75rem",
        fontWeight: 600
      },
      h4: {
        fontSize: "1.5rem",
        fontWeight: 500
      },
      h5: {
        fontSize: "1.25rem",
        fontWeight: 500
      },
      h6: {
        fontSize: "1rem",
        fontWeight: 500
      },
      body1: {
        fontSize: "1rem"
      },
      body2: {
        fontSize: "0.875rem"
      },
    },
    palette: {
      mode: darkMode ? 'dark' : 'light',
      primary: {
        main: darkMode ? '#000' : '#fff',
      },
      secondary: {
        main: darkMode ? '#fff' : '#000',
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'capitalize'
          }
        }
      },
      MuiCssBaseline: {
        styleOverrides: `
              @font-face {
                font-family: 'AkaAcidBlogger';
                src: url(${AkaAcidBloggerOtf}) format('opentype'),
                url(${AkaAcidBloggerTtf}) format('truetype');
                font-weight: normal;
                font-style: normal;
                font-display: swap;
              }
            `,
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            color: 'black'
          }
        }
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            background: "white",
            color: 'black'
          }
        }
      },
      MuiCard: {
        styleOverrides: {
          root: {
            borderRadius: 10,
            marginBottom: '10px',
            // border: '1px solid #00000014',
            // boxShadow: '#00000065 0px 0px 10px -3px',
            boxShadow: 'none'
          },
        },
      },
      MuiCardHeader: {
        styleOverrides: {
          root: {
            padding: '5px 10px',
          },
          // subheader: {
          //   color: 'white'
          // }
        },
      },
      MuiCardContent: {
        styleOverrides: {
          root: {
            padding: '5px 10px',
          },
        },
      },
      MuiCardActions: {
        styleOverrides: {
          root: {
            padding: '0px 8px',
          },
        },
      },
      MuiToolbar: {
        styleOverrides: {
          root: {
            minHeight: '48px',
          },
        },
      },
      MuiBottomNavigation: {
        styleOverrides: {
          root: {
            height: '56px',
          },
        },
      },
      MuiFab: {
        styleOverrides: {
          root: {
            width: '48px',
            height: '48px',
          },
        },
      },
      MuiBottomNavigationAction: {
        styleOverrides: {
          root: {
            minWidth: 'auto',
            '& .MuiBottomNavigationAction-label': {
              '&.Mui-selected': {
                color: darkMode ? 'white' : 'black',
              }
            },
            '& .MuiSvgIcon-root': {
              color: darkMode ? 'white' : 'black',
              // width: 28,
              // height: 28,
            },
          },
        },
      },
      MuiImageList: {
        styleOverrides: {
          root: {
            margin: 0,
            padding: '10px'
          }
        }
      },
      MuiImageListItem: {
        styleOverrides: {
          root: {
            boxShadow: darkMode ? '#ffffff65 0px 0px 10px 1px' : '#00000065 0px 0px 10px 1px',
            borderRadius: '10px',
            justifyContent: 'center',
            overflow: 'hidden',
            '& img': {
              borderRadius: '10px',
            },
            '&.selected': {
              borderRadius: '14px',
              border: darkMode ? '4px solid rgb(255 255 255)' : '4px solid rgb(0, 0, 0)',  
            },
            '& .MuiImageListItemBar-root': {
              borderRadius: '10px',
            },
          }
        }
      },
      MuiImageListItemBar: {
        styleOverrides: {
          root: {
            '& .MuiImageListItemBar-titleWrap': {
              padding: 0,
            },
          }
        }
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            '& p': {
              margin: 0
            }
          }
        }
      }
    },
  })

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          
        <Route path="/" element={<LandingPage />} />
          {/* Public Routes */}
          <Route element={<PublicRoute />}>
            <Route path=":id?" element={<Login/>} />
            {/* <Route path=":id?/signin" element={<Login/>} /> */}
            <Route path=":id?/signup" element={<Register/>} />
            <Route path=":id?/signin" element={<RegisterGuest/>} />

            <Route path=":id?/sign-up/sso-callback" element={<SSOCallback />} />
            <Route path=":id?/sign-in/sso-login" element={<SSOLogin />} />
          </Route>


          {/* Protected Routes */}
          <Route element={<ProtectedRoute />}>
            {/* <Route path=":id" element={<Dashboard/>} /> */}
            {/* <Route path=":id?/dashboard" element={<Dashboard />} /> */}
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="profile" element={<Profile />} />
            <Route path="events" element={<Events />} />
            <Route path="settings" element={<Settings />} />
          </Route>
        </Routes>
      </Router>
    </ThemeProvider>
  )
}