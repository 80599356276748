import { useNavigate, useParams } from "react-router-dom";
import useEventStore from "Store/eventStore";

const useNavigateCustom = () => {
  const navigateTo = useNavigate();
  const { setUuid } = useEventStore();
  const { id } = useParams<{ id: string }>(); // Extract `id` from URL

  const navigate = (path: string, options?: object) => {
    // if (!id) {
    //   console.error("ID is missing in the URL!");
    //   return;
    // }
    // setUuid(id);
    navigateTo(`${path}`, options);
    // navigateTo(`/${id}${path}`, options);
  };

  const changeId = (newId: string) => {
    if (!newId) {
      console.error("New ID is required!");
      return;
    }
    if (newId === id) {
      console.warn("New ID is required!");
      return;
    }

    setUuid(newId);

    navigateTo(`/${newId}`, { replace: true });
  };
  return { navigate, changeId };
};

export default useNavigateCustom;
