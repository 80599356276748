import * as React from 'react'
import ImageList from '@mui/material/ImageList'
import ImageListItem from './ImageListItem'

interface Props {
    items: Item[],
    onItemSelect?: (id: number) => void
    handleDoubleClick?: (id: number) => void
}
interface Item {
    id: number,
    src: string,
    srcSet?: string,
    title: string,
    author: number,
    featured?: boolean,
    fullWidth?: boolean,
    bottomRightButton?: any,
    isSelected?: boolean
}
const EventList: React.FC<Props> = (props: Props) => {

  if (!props.items) return null
  return (
    <ImageList
      sx={{
        width: '100%',
        height: '100%',
        // Promote the list into its own layer in Chrome. This costs memory, but helps keeping high FPS.
        transform: 'translateZ(0)',
        overflowWrap: 'anywhere'
      }}
      cols={3}
      rowHeight={100}
      gap={10}
    >
      {props.items.map((item) => {
        return <ImageListItem
            key={item.id}
            {...item}
            isSelected={item.isSelected}
            onItemSelect={props.onItemSelect}
            handleDoubleClick={props.handleDoubleClick}
        />
      })}
    </ImageList>
  )
}

export default EventList

const itemData2 = [
  {
    id: 1,
    img: 'https://images.unsplash.com/photo-1551963831-b3b1ca40c98e',
    title: 'Breakfast',
    author: '@bkristastucchio',
    featured: true,
  },
  {
    id: 2,
    img: 'https://images.unsplash.com/photo-1551782450-a2132b4ba21d',
    title: 'Burger',
    author: '@rollelflex_graphy726',
  },
  {
    id: 3,
    img: 'https://images.unsplash.com/photo-1522770179533-24471fcdba45',
    title: 'Camera',
    author: '@helloimnik',
  },
  {
    id: 4,
    img: 'https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c',
    title: 'Coffee',
    author: '@nolanissac',
  },
  {
    id: 5,
    img: 'https://images.unsplash.com/photo-1533827432537-70133748f5c8',
    title: 'Hats',
    author: '@hjrc33',
  },
  {
    id: 6,
    img: 'https://images.unsplash.com/photo-1558642452-9d2a7deb7f62',
    title: 'Honey',
    author: '@arwinneil',
    featured: true,
  },
  {
    id: 7,
    img: 'https://images.unsplash.com/photo-1516802273409-68526ee1bdd6',
    title: 'Basketball',
    author: '@tjdragotta',
  },
  {
    id: 8,
    img: 'https://images.unsplash.com/photo-1518756131217-31eb79b20e8f',
    title: 'Fern',
    author: '@katie_wasserman',
  },
  {
    id: 9,
    img: 'https://images.unsplash.com/photo-1597645587822-e99fa5d45d25',
    title: 'Mushrooms',
    author: '@silverdalex',
  },
  {
    id: 10,
    img: 'https://images.unsplash.com/photo-1567306301408-9b74779a11af',
    title: 'Tomato basil',
    author: '@shelleypauls',
  },
  {
    id: 11,
    img: 'https://images.unsplash.com/photo-1471357674240-e1a485acb3e1',
    title: 'Sea star',
    author: '@peterlaster',
  },
  {
    id: 12,
    img: 'https://images.unsplash.com/photo-1589118949245-7d38baf380d6',
    title: 'Bike',
    author: '@southside_customs',
  },
]
