import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Select, FormControlLabel, MenuItem } from '@mui/material'

interface Props {}
const Language: React.FC<Props> = () => {
  const { t, i18n } = useTranslation()

  const changeLanguageHandler = (lang: string) => {
    i18n.changeLanguage(lang)
  }

  return (
    <>
      <FormControlLabel
        sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row-reverse', margin: 0 }}
        control={
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={i18n.language}
            label={t('lang_select')}
            onChange={(e) => changeLanguageHandler(e.target.value)}
          >
            <MenuItem value={'en'}>{t('lang_english')}</MenuItem>
            <MenuItem value={'el'}>{t('lang_greek')}</MenuItem>
          </Select>
        }
        label={t('select_language')}
      />
    </>
  )
}
export default Language
